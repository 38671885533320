<template>
  <div>

    <div id="vue" class="wrap">
      <div class="container">
        <div class="mt-4">
          <h3 class="mr-4 mb-3">
            <i class="fas fa-wrench mr-2"></i>
            路燈報修
          </h3>

          <div class="btn-group btn-group-toggle mb-1" data-toggle="buttons">
            <label class="btn btn-outline-secondary active ">
              <input type="radio" name="options" id="option1" autocomplete="off" @click="isId = true" checked> 知道路燈編號
            </label>
            <label class="btn btn-outline-secondary">
              <input type="radio" name="options" id="option2" @click="isId = false" autocomplete="off"> 不知道路燈編號
            </label>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <h4>路燈資訊</h4>
                  </div>
                </div>
                <div class="row" v-show="isId">
                  <div class="col-sm-6">
                    <div class="form-group form-group-lg">
                      <label for="light-id" class="required">路燈編號</label>
                      <input type="text" class="form-control" placeholder="請填入7位數字" v-model="streetlight_no"
                        @blur="queryInfo" required>
                      <a href="javascript:;" @click="showHowModal">路燈編號怎麼看?</a>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="fault-type" class="required">故障類別</label>
                      <select name="fault-type" id="fault-type" class="form-control selectpicker" title="請選擇"
                        v-model="malfunction_type">
                        <option v-for="(failure, index) in failure_list" :key="index" :value="failure.id">
                          {{ failure.option }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12">
                    <h5>地址 / 位置</h5>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="district" class="required">行政區</label>
                      <select name="district" id="district" class="form-control selectpicker" title="請選擇" disabled
                        v-model="district_id">
                        <option v-for="d in district_list" :key="d.id" :value="d.id">{{ d.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="village">里</label>
                      <select name="village" id="village" class="form-control selectpicker" title="請選擇" disabled
                        v-model="village">
                        <option v-for="v in village_list" :key="v.id" :value="v.id">{{ v.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="" class="required">詳細位置</label>
                      <input type="text" class="form-control" v-model="address" disabled required
                        style="background-color: #e9ecef;">
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="" class="required">姓名</label>
                      <input type="text" class="form-control" v-model="citizen_name" required>
                    </div>
                  </div>
                  <div class="w-100"></div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="" class="required">聯絡電話</label>
                      <input type="text" class="form-control" v-model="phone" required>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Email</label>
                      <input type="text" class="form-control" v-model="email">
                      <div class="mt-1">
                        <div class="form-check">
                          <input type="checkbox" class="form-check-input checkbox-md" id="done_email"
                            v-model="receive_email">
                          <label class="form-check-label" for="done_email">接收完工email通知</label>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label>備註</label>
                        <textarea class="form-control" v-model="remark" placeholder="如地址錯誤，請在此輸入正確地址"></textarea>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                </div>

                <div class="row" v-show="!isId">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="fault-type" class="required">故障類別</label>
                      <select name="fault-type" id="fault-type" class="form-control selectpicker" title="請選擇"
                        v-model="malfunction_type">
                        <option v-for="(failure, index) in failure_list" :key="index" :value="failure.id">
                          {{ failure.option }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12">
                    <h5>地址 / 位置</h5>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="district" class="required">行政區</label>
                      <select name="district" id="district" class="form-control selectpicker" title="請選擇"
                        v-model="district_id">
                        <option v-for="d in district_list" :key="d.id" :value="d.id">{{ d.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="village" class="">里</label>
                      <select name="village" id="village" class="form-control selectpicker" title="請選擇" v-model="village">
                        <option v-for="v in village_list" :key="v.id" :value="v.id">{{ v.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="" class="required">詳細位置</label>
                      <input type="text" class="form-control" v-model="address" required>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="" class="required">姓名</label>
                      <input type="text" class="form-control" v-model="citizen_name" required>
                    </div>
                  </div>
                  <div class="w-100"></div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="" class="required">聯絡電話</label>
                      <input type="text" class="form-control" v-model="phone" required>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Email</label>
                      <input type="text" class="form-control" v-model="email">
                      <div class="mt-1">
                        <div class="form-check">
                          <input type="checkbox" class="form-check-input checkbox-md" id="done_email2"
                            v-model="receive_email">
                          <label class="form-check-label" for="done_email2">接收完工email通知</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label>備註</label>
                      <textarea class="form-control" v-model="remark" placeholder="如地址錯誤，請在此輸入正確地址"></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="">上傳照片(最多三張)</label>
                      <div class="row">
                        <div class="col-sm-4">
                          <input type="file" class="dropify" @change="selectImg(1)" accept="image/*" />
                        </div>
                        <div class="col-sm-4">
                          <input type="file" class="dropify" @change="selectImg(2)" accept="image/*" />
                        </div>
                        <div class="col-sm-4">
                          <input type="file" class="dropify" @change="selectImg(3)" accept="image/*" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12">
                    <div class="alert alert-greenApple" role="alert">
                      <h4 class="alert-heading">服務條款</h4>
                      <p>依據桃園市政府(以下簡稱本府)規範，此路燈故障填寫內容為實際情況，若有不實通報或惡意報修情形，本府將不受理該案件，並將相關IP提供至執法機關且保留法律追訴權。</p>
                      <hr>
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input checkbox-md" id="check1"
                          v-model="is_accept_service_policy">
                        <label class="form-check-label" for="check1">本人同意上述條款，且報修資料正確無誤。</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="alert alert-greenApple" role="alert">
                      <h4 class="alert-heading">個人資料保護法</h4>
                      <p>
                        桃園市政府(以下簡稱本府)可能蒐集您個人相關資訊，包括但不限於姓名、電話、電子郵件地址及其他可直接或間接識別使用者身分之個人資料、ＩＰ位址、停留時間之記錄。本府依據個人資料保護法（下稱個資法）定義，您保有一切符合個資法規範之權利
                        <br>本府有權隨時修訂本聲明及相關告知事項，並得於修訂後公佈於本府網站，不另行個別通知，更新的隱私權條款仍對您發生效力，因此，您有義務亦隨時在本府網站上詳閱最新版本的聲明及相關告知事項。"
                      </p>
                      <hr>
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input checkbox-md" id="check2"
                          v-model="is_accept_personal_private">
                        <label class="form-check-label" for="check2">已審閱並同意個資告知事項。</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label>驗證碼</label>
                      <input type="text" class="form-control" v-model="verify_code" placeholder="驗證碼不分大小寫">
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label></label>
                      <img :src="verifyUrl" alt="" style="max-width: 100%;">
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label></label>
                      <img @click="refreshVerify" class="mt-5 ml-2" style="max-height: 1.5rem; cursor: pointer;"
                        src="/assets/images/arrow-rotate-right-solid.svg">
                    </div>
                  </div>
                </div>
                <hr class="border-secondary">
                <div class="d-flex justify-content-end">
                  <button style="width: 150px;" class="btn btn-outline-light mr-3" @click="resetForm">清空重填</button>
                  <button style="width: 150px;" class="btn btn-outline-info" @click="onSubmit">送出</button>
                  <!-- <button style="width: 150px;" class="btn btn-outline-info" @click="report">送出</button> -->
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- modal 路燈編號怎麼看 -->
    <div id="howModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content bg-gray">
          <div class="modal-header">
            <h4 class="modal-title m-0">路燈編號怎麼看?</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> × </button>
          </div>
          <div class="modal-body text-center">
            <img src="/assets/images/pole-number.png" alt="">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-info waves-effect waves-light" data-dismiss="modal">知道了</button>
          </div>
        </div>
      </div>
    </div>

    <!-- modal 路燈編號已報修 -->
    <div id="duplicateModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content bg-gray">
          <div class="modal-header">
            <h4 class="modal-title m-0">路燈編號已報修</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> × </button>
          </div>
          <div class="modal-body text-center">
            <div class="tools-icon"><i class="las la-tools"></i></div>
            <h4>此路燈編號已報修，可於維修查詢中查詢維修進度。</h4>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-info waves-effect waves-light" data-dismiss="modal">知道了</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { api } from "../../../apis/main.js"
import { Toast } from '../../../assets/helpers.js';
let $ = window.$;
let key_name_mapping = {
  streetlight_no: "路燈編號",
  malfunction_type: "故障類別",
  citizen_name: "姓名",
  phone: "電話",
  address: "地址",
  email: "Email",
  remark: "備註",
  district_id: "區資訊",
  village_id: "里資訊",
  vertification_str: "驗證碼",
  is_accept_service_policy: "服務條款",
  is_accept_personal_private: "個資條款",
}
export default {
  name: 'ReportPage',
  props: {
    msg: String
  },
  data() {
    return {
      isId: true,
      region_list: [],
      failure_list: [],
      streetlight_no: "",
      malfunction_type: "",
      citizen_name: "",
      phone: "",
      address: "",
      email: "",
      receive_email: false,
      remark: "",
      district_id: "",
      village: "",
      files: [],
      verify_code: "",
      encrypted_vertification: "",
      is_accept_service_policy: undefined,
      is_accept_personal_private: undefined,
      verifyUrl: '',
    }
  },
  created: function () {
    let _this = this;
    this.failureReason().then(res => {
      _this.failure_list = res.data;
    });

    this.tmp = JSON.parse(JSON.stringify(this.$data));
  },
  mounted: function () {
    $('.dropify').dropify({
      messages: {
        'default': '點擊或拖曳上傳檔案',
        'replace': '點擊或拖曳上傳檔案',
        'remove': '移除檔案',
        'error': 'Ooops, something wrong happended.'
      }
    });
    $('.selectpicker').selectpicker({
      container: 'body',
      size: 6,
      dropupAuto: false
    });
    this.getRegion().then(res => {
      this.region_list = res.data
      this.region_list.unshift({
        id: '',
        name: ''
      });
      this.$nextTick(() => {
        $('.selectpicker').selectpicker('refresh')
      })
    });
    this.refreshVerify();
  },
  computed: {
    district_list() {
      if (this.region_list.length == 0) return [];
      return this.region_list.map(ele => {
        return {
          id: ele.id,
          name: ele.name
        }
      });
    },
    village_list() {
      let d_id = this.district_id;
      if (d_id == '') return [];
      let res = this.region_list.filter(ele => {
        return ele.id == d_id;
      })[0].village_list;
       res.unshift({
        id: '',
        name: ''
      });
      return res;
    },
  },
  methods: {
    queryInfo() {

      let self = this;
      api.get('get_streetlight_information', {
        params: {
          streetlight_no: this.streetlight_no
        }
      }).then(res => {
        this.district_id = res.data.district_id
        this.village = res.data.village_id
        this.address = res.data.full_address
      }).catch(err => {

        swal({
          html: true,
          title: "<br>請再提供更多位置資訊給我們，謝謝",
          // type: "warning",   
          showCancelButton: true,
          confirmButtonColor: "#0880AE",
          confirmButtonText: "確認",
          cancelButtonText: "取消",
          // closeOnConfirm: false 
        }, () => {
          $('#option2').trigger('click');
          // self.isId = false;
          let no = this.streetlight_no;
          self.remark = no;
          this.streetlight_no = '';
        });

        this.district_id = ''
        this.village = undefined
        this.address = undefined
      })
    },
    refreshVerify() {
      let res = api.get("get_vertification", { responseType: 'blob' });
      res.then(res => {
        this.verifyUrl = window.webkitURL.createObjectURL(res.data);
        this.encrypted_vertification = res.headers['content-disposition'].split('=')[1];
      })
      // this.verifyUrl = this.verifyUrl + "?time=" + new Date().getTime();
    },
    showHowModal() {
      $('#howModal').modal('show')
    },
    onSubmit() {
      swal({
        title: "確認要送出報修資訊？",
        // type: "warning",   
        showCancelButton: true,
        confirmButtonColor: "#0880AE",
        confirmButtonText: "確認",
        cancelButtonText: "取消",
        // closeOnConfirm: false 
      }, () => {
        this.report().then(res => {
          Toast({
            icon: 'success',
            heading: '送出成功',
            text: '路燈已報修成功'
          });
          this.refreshVerify();
          this.resetForm();
          // this.verifyUrl = this.verifyUrl + "?time=" + new Date().getTime();
        }).catch(reason => {
          console.log(reason)
          if (reason.response.status == 400) {
            Toast({
              icon: 'error',
              heading: '回報失敗',
              text: reason.response.data.detail
            })
            // $('#duplicateModal').modal('show')
          } else if (reason.response.status == 422) {
            Toast({
              icon: 'error',
              heading: '回報失敗',
              text: key_name_mapping[reason.response.data.detail[0].loc[1]] + "未填"
            })
          }
          this.refreshVerify();
          // this.verifyUrl = this.verifyUrl + "?time=" + new Date().getTime();
        });
      });
    },
    selectImg(id) {
      window.test = event.target
      if (event.target.files[0].size / 1024 / 1024 > 2) {
        event.target.files = null
        $(event.target).parent().find('.dropify-clear').click();
        Toast({
          icon: 'error',
          heading: '錯誤',
          text: '檔案大小超過限制2MB',
        })
      } else {
        this.files[id] = event.target.files;
      }
    },
    showHowModal() {
      $('#howModal').modal('show')
    },
    async failureReason() {
      let res = await api.get('get_select_option', {
        params: {
          major_system: "民眾報修網站",
          sub_system: "路燈報修",
          cust_field: "故障原因",
        }
      });
      return res;
    },
    async report() {
      let _data = {
        malfunction_type: this.malfunction_type,
        citizen_name: this.citizen_name,
        phone: this.phone,
        address: this.address,
        email: this.email,
        receive_email: this.receive_email,
        remark: this.remark,
        vertification_str: this.verify_code,
        encrypted_vertification: this.encrypted_vertification,
        is_accept_service_policy: this.is_accept_service_policy,
        is_accept_personal_private: this.is_accept_personal_private,
        district_id: this.district_id,
        village_id: this.village,
      };
      let data = this.isId ? { streetlight_no: this.streetlight_no } : {};
      // if(this.files.length > 0) {
      //   data.files = [];
      //   for(let id in this.files) {
      //     data.files.push(this.files[id][0]);
      //   }
      // }
      let url = this.isId ? "notify_maintenance_with_streetlight_no" : "notify_maintenance_without_streetlight_no";

      if (this.village == '' || this.village == undefined || this.village == null) {
        delete _data.village_id;
      }

      let formdata = new FormData();
      data = { ..._data, ...data };
      console.log(data)

      for (let key of Object.keys(data)) {
        formdata.append(key, data[key]);
      }
      if (this.files.length > 0) {
        for (let id in this.files) {
          formdata.append('files', this.files[id][0]);
        }
      }
      let res = await api.post(url, formdata, {
        headers: { 'Content-Type': 'multipart/form-data' },
        // withCredentials: true
      });
      return res;
    },
    async getRegion() {
      let res = await api.get("get_district_village_option");
      return res;
    },
    resetForm() {
      let _region_list = [...this.region_list]
      for (let i of Object.keys(this.$data)) {
        if (i !== 'failure_list') {
          this[i] = this.$options.data()[i];
        }
      }
      $('.dropify-clear').click();
      this.refreshVerify();
      this.region_list = _region_list;
      this.$nextTick(() => {
        $('.selectpicker').selectpicker('refresh')
      })
    }
  },
  watch: {
    district_id: function () {
      this.$nextTick(() => {
        $('.selectpicker').selectpicker('refresh')
      })
    },
    region_list: {
      handler(val) {
        console.log('update selectpicker')
        $('.selectpicker').selectpicker('refresh')
      },
      immediate: true
    }
  }
}
</script>

<style scoped></style>
